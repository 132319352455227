import React, { useState } from 'react';
import Header from '../components/Header';
import house from "../assets/house.png";
import ContactUs from '../components/ContactUs';
import './Home.css';
import Carousel from '../components/Carousel';
import PropertyManagement from '../components/PropertyManagement';
import WhereWeOperate from '../components/WhereWeOperate';
import Footer from '../components/Footer';
import Airbnbservice from '../components/Airbnbservice';
import Reviews from '../components/Reviews';
import CaseStudy from '../components/CaseStudy';
import BookingBanner from '../components/BookingBanner';



const Home = () => {

  const [showPopupOnClick, setShowPopupOnClick] = useState(false);

  const popupStyleButton = {
    position: 'fixed',
    top: '35%',
    left: '50%',
    height:'60%',
    transform: showPopupOnClick ? 'translate(-50%, -50%)' : 'translate(-50%, -150%)',
    backgroundColor: 'black',
    display: showPopupOnClick ? 'block' : 'none',
    zIndex: 9999,
    borderRadius: '15px', // Adds a border radius
   
  };

  const handleClose = () => {
    setShowPopupOnClick(false);
  };





  return (

    
    <div className='main-body-home'>
          <div className="popup" style={popupStyleButton}>

                  <button className="close-button" onClick={handleClose}>
                      X
                    </button>
                    <ContactUs/>
                  </div>


    <div className='header-main'>
          <Header />
        </div>
      <div className='home-top'>
        <img className="house" src={house} alt="really nice looking house" />
        
        <div className='des-headers'>
          <h1 className='main-head'>Short term property<br />management service</h1>
          <h2 className='sec-head'>Airbooked provides end-to-end property management service, 
          taking the operational stress away from running your short term rental.</h2>
          
          <button className="book-chat-top" onClick={() => setShowPopupOnClick(true)} >Book a free appraisal</button>
        
        </div>

        
      </div>

        <div className="property-management-container">
            <h3 className="heading-prop">We will increase your <br/>booking rate.</h3>
            <p className="body-text-prop">
              We’re a licensed real estate agency offering premium Short and long term property management services across Australia. 
              With our expertise, we ensure your property shines in the market and deliver seamless, professional management 
              from start to finish.
            </p>
            <p className="body-text-prop">
              Our dedication goes beyond property owners – we also take pride in providing exceptional guest services, ensuring 
              every guest feels valued and well cared for.
            </p>
            <p className="body-text-prop">
              From beginning to end, we’re here to support both you and your guests at every stage.
            </p>
      </div>

       <div className='caro-container'>
        <Carousel/>
      </div>

    
        <BookingBanner/>

    

      <div className="property-management-container">
            <h3 className="heading-prop">Maximize Your Short-Term <br/>Rental Success with AirBooked.</h3>
            <p className="body-text-prop">
             At AirBooked, we specialize in transforming traditional long-term rentals into premium short-term vacation stays. 
             Our full-service property management approach helps you boost your rental income, reduce the time spent searching 
             for guests, and increase overall profitability.
            </p>
            <p className="body-text-prop">
              With our expert team handling every detail, you’ll enjoy more free time and peace of mind.
            </p>
      </div>
        <CaseStudy/>


      <div className='service-section'>
      <Airbnbservice/>
      </div>
      <PropertyManagement/>
      <Reviews/>
      <WhereWeOperate/>
      <Footer/>


    </div>
  );
}

export default Home;
