import React from 'react';
import './CaseStudy.css';
import clean from "../assets/clean.png";
import { CiDollar, CiShoppingTag, CiCalendarDate, CiFaceSmile } from "react-icons/ci";
import Host from "../assets/host.png"



const CaseStudy = () => {
  return (

    <div className="property-container">

    
      <div className="property-header">
        <h1 className="property-title">Unlock your home's earning potential</h1>
      </div>

        <div className='prop-descrip-con'>

            <div className="property-content">
                <div className="property-image-wrapper">
                <img className="clean-im" src={clean} alt="logo"/>
                </div>
                    <div className="property-details">
                    <h2 className="property-name">Fitzroy Terrace House</h2>
                    <p className="property-location">
                        Guesthouse in Fitzroy, Melbourne.
                    </p>
                    <p className="property-attributes">6 guests • 3 bedrooms • 4 beds • 2 bath</p>
                    <div className="property-host-details">
                        <img className="host" src={Host} alt="host" />
                        <p className="property-host">Hosted by Shaun</p>
                    </div>
  
                </div>
            </div>

            <div className='stats-container'> 
               <div className='stats-des'>
                    <p className="property-description">
                        Earn superior returns on your property through the short-term rental market.
                    </p>
                    <p className="property-story">
                        This renovated terrace house in Fitzroy was rented as a standard long-term tenancy before being 
                        converted to an Airbnb. Since the property was located in an Airbnb hotspot, 
                        the owner saw a 30% increase in rental revenue compared to the traditional long-term tenancy.
                    </p>
                </div>

          <div className="property-stats">
            <div className="property-stat">
              <p className="stat-value"><CiDollar size={40} /> $87,100</p>
              <p className="stat-label">Yearly Rental Income</p>
            </div>
            <div className="property-stat">
              <p className="stat-value"><CiShoppingTag size={40} /> $520</p>
              <p className="stat-label">Average Price Per Night</p>
            </div>
            <div className="property-stat">
              <p className="stat-value"><CiCalendarDate size={40} /> 75%</p>
              <p className="stat-label">Occupancy Rate</p>
            </div>
            <div className="property-stat">
              <p className="stat-value"><CiFaceSmile size={40}/> 4.8 / 5</p>
              <p className="stat-label">Rating</p>
            </div>
          </div>

          </div>  


        </div>
      


    </div>
  );
};

export default CaseStudy;
