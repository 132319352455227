import React, { useState, useEffect } from 'react';
import './Carousel.css'; // Import CSS
import image1 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';
import image4 from '../assets/image4.png';
import image5 from '../assets/image5.png';
import image6 from '../assets/image6.png'

const carouselData = [
  {
  imgSrc: image1,
  heading: 'Turn over clean service',
  paragraph: 'We take care of the full turn over cleaning service, this is key for maintaining a 5 star rating.'
},

{
  imgSrc: image2,
  heading: 'Multi-Channel Advertising',
  paragraph: 'We promote your property on over 20 platforms to increase visibility and maximize earnings.'
},
{
  imgSrc: image3,
  heading: 'Fast Weekly Payouts',
  paragraph: 'We offer weekly payouts, giving you regular access to your earnings without long waits.'
},
{
  imgSrc: image4,
  heading: 'Fully Licensed and Australian-Owned',
  paragraph: 'AirBooked is Australian-owned and fully licensed, giving you professional service and peace of mind.'
},
{
  imgSrc: image5,
  heading: 'Clear Insights and Data',
  paragraph: 'Stay informed with easy-to-understand appraisals and transparent booking details through our Owner Dashboard.'
},
{
  imgSrc: image6,
  heading: 'Smart, Data-Driven Pricing',
  paragraph: 'Our pricing strategy is based on real-time data, keeping your property competitive and maximizing returns.'
},

];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(3); // Default to 3 for desktop

  // Dynamically set the number of items based on window size
  useEffect(() => {
    const updateItemsToShow = () => {
      if (window.innerWidth < 768) {
        setItemsToShow(1); // Show 1 item for mobile view
      } else {
        setItemsToShow(3); // Show 3 items for desktop view
      }
    };

    // Update the number of items when the window resizes
    window.addEventListener('resize', updateItemsToShow);

    // Set the correct number of items on initial load
    updateItemsToShow();

    // Cleanup event listener
    return () => window.removeEventListener('resize', updateItemsToShow);
  }, []);

  const nextSlide = () => {
    if (currentIndex < carouselData.length - itemsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="carousel-container">
      <h3 className="carousel-title">Why choose AirBooked</h3>
      <div className="carousel-wrapper">
        <button className="carousel-arrow left-arrow" onClick={prevSlide}>&lt;</button>
        <div className="carousel">
          {carouselData.slice(currentIndex, currentIndex + itemsToShow).map((item, index) => (
            <div className="carousel-item" key={index}>
              <img src={item.imgSrc} alt={item.heading} className="carousel-img" />
              <h4>{item.heading}</h4>
              <p className='why-choose'>{item.paragraph}</p>
            </div>
          ))}
        </div>
        <button className="carousel-arrow right-arrow" onClick={nextSlide}>&gt;</button>
      </div>
    </div>
  );
};

export default Carousel;




