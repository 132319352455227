import React from 'react';
import './Reviews.css';

// Importing images from the assets folder
import profilePic1 from '../assets/profilePic1.png';
import profilePic5 from '../assets/profilePic5.png';  
import profilePic4 from '../assets/profilePic4.png';  
import profilePic2 from '../assets/profilePic2.png';  




const reviewsData = [
  {
    review: "Shaun made managing my property a breeze. Shaun and his team is responsive, professional, and trustworthy. Highly recommend!",
    nameInitial: "J.Veed",
    profilePic: profilePic1,
  },
  {
    review: "Fantastic service from Shaun! My property has seen a uplift in occupancy rate, and the communication is top-notch.",
    nameInitial: "A.Demintrios",
    profilePic: profilePic2 ,
  },
  {
    review: "I couldn't be happier with AirBooked's services. No longer have issues with cleaners, and everything is handled for me.",
    nameInitial: "S.Bell",
    profilePic: profilePic1,
  },
  {
    review: "Professional and reliable. They take care of all the details so I can focus on other things.",
    nameInitial: "M.Mckenna",
    profilePic: profilePic4,
  },
  {
    review: "Highly recommend AirBooked! They’ve transformed the way I manage my property, and the results speak for themselves.",
    nameInitial: "L.Cambell",
    profilePic: profilePic5,
  },
];

const Reviews = () => {
  return (
    <div className="reviews-container">
      <h3>"What our customers<br/> think"</h3>
      {reviewsData.map((review, index) => (
        <div key={index} className="review">
          <div className="stars">
            {Array(5)
              .fill()
              .map((_, i) => (
                <span key={i} className="star">&#9733;</span>
              ))}
          </div>
          <p className="review-text">{review.review}</p>
          <div className="reviewer"><img src={review.profilePic} alt="Profile" className="reviewer-pic" />
            <span className="reviewer-name">{review.nameInitial}</span>
            
          </div>
        </div>
      ))}
    </div>
  );
};

export default Reviews;
