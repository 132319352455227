import React from 'react';
import './Footer.css'; // Create a separate CSS file for styling
import logo from "../assets/airbooked.png";


const Footer = () => {
  return (
    <footer className="footer">
      

      <div className="footer-content">
      
        {/* Company Information Section */}
        <div className="footer-info">
        
          <p className="footer-info-text">
          <div className="footer-logo">
           <img className="logo" src={logo} alt="logo"/>
          </div>
            Our company is a leading provider in Australia, managing properties nationwide, including key locations 
            across Victoria and queensland. We focus on enhancing homeowners' earnings while ensuring a smooth 
            and memorable experience for guests by leveraging cutting-edge technology and a skilled local team."
          </p>
        </div>

        {/* Contact Section */}
        <div className="footer-contact">
          <p className="footer-contact-item">Email: <a className="footer-contact-link" href="mailto:sales@sprucelyclean.com">sales@sprucelyclean.com</a></p>
          <p className="footer-contact-item">Phone: <a className="footer-contact-link" href="tel:+043331096">0433310964</a></p>
        </div>

        {/* Services Section */}
        <div className="footer-services">
          <h4 className="footer-services-title">SERVICES</h4>
          <ul className="footer-services-list">
            <li className="footer-services-item"><a className="footer-services-link" href="/services/property-styling">Property Styling</a></li>
            <li className="footer-services-item"><a className="footer-services-link" href="/services/booking-management">Booking Management</a></li>
            <li className="footer-services-item"><a className="footer-services-link" href="/services/short-stay-marketing">Short Stay Marketing</a></li>
            <li className="footer-services-item"><a className="footer-services-link" href="/services/customer-communication">Customer Communication</a></li>
            <li className="footer-services-item"><a className="footer-services-link" href="/services/professional-cleaning">Professional Cleaning Service</a></li>
            <li className="footer-services-item"><a className="footer-services-link" href="/services/guest-stays">Guest Stays</a></li>
            <li className="footer-services-item"><a className="footer-services-link" href="/services/maintenance-management">Maintenance Management</a></li>
            <li className="footer-services-item"><a className="footer-services-link" href="/services/host-mentoring">Host Mentoring</a></li>
            <li className="footer-services-item"><a className="footer-services-link" href="/services/guest-reviews">Guest Reviews</a></li>
          </ul>
        </div>

        {/* Locations Section */}
        <div className="footer-locations">
          <h4 className="footer-locations-title">LOCATIONS</h4>
          <ul className="footer-locations-list">
            <li className="footer-locations-item"><a cla sName="footer-locations-link" href="/locations/melbourne">Melbourne</a></li>
            <li className="footer-locations-item"><a className="footer-locations-link" href="/locations/adelaide">Adelaide</a></li>
            <li className="footer-locations-item"><a className="footer-locations-link" href="/locations/lorne-to-geelong">Lorne to Geelong</a></li>
            <li className="footer-locations-item"><a className="footer-locations-link" href="/locations/sunshine-coast">Sunshine Coast</a></li>
            <li className="footer-locations-item"><a className="footer-locations-link" href="/locations/cairns">Cairns</a></li>
            <li className="footer-locations-item"><a className="footer-locations-link" href="/locations/brisbane">Brisbane</a></li>
            <li className="footer-locations-item"><a className="footer-locations-link" href="/locations/gold-coast">Gold Coast</a></li>
            <li className="footer-locations-item"><a className="footer-locations-link" href="/locations/port-douglas">Port Douglas</a></li>
            <li className="footer-locations-item"><a className="footer-locations-link" href="/locations/port-douglas">Country Victoria</a></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="footer-bottom-text">&copy; {new Date().getFullYear()} Airbooked. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;


