import React from 'react';
import './PropertyManagement.css'; // Link to your updated CSS file
import PriceCard from './PriceCard';


const PropertyManagement = () => {
  return (

    <div className='back-container'>
    <PriceCard/>
    <div className="property-management">
      <h3 className="heading-prop align-c">How Does AirBooked's Property Management Work?</h3>
      <p className="body-text-prop body-prop-m align-c">
        AirBooked provides a dependable, guest-friendly property management service designed for both owners and their guests. 
        Our fee structure is simple: you only pay when you earn. From cleaning to guest interactions, we handle every detail so 
        you can enjoy peace of mind and focus on what matters most.
      </p>

      <div className="box-container">
        <div className="box">
          <span className="box-number">1</span>
          <h4>Property Assessment</h4>
          <p>
            Schedule a call or reach out to us for vacation rental property management. We’ll evaluate your property’s 
            potential and provide a personalized assessment within 24 hours, including realistic projections and a 
            market analysis based on your goals and vision for the property. 
            Once you’re ready, simply sign up, and we’ll take care of the rest!
          </p>
        </div>

        <div className="box">
          <span className="box-number">2</span>
          <h4>Onboarding & Listing Creation</h4>
          <p>
            After signing up, you’ll be introduced to your Area Manager at AirBooked—your dedicated point of contact who will 
            oversee all aspects of your property management. We’ll arrange a property inspection, coordinate professional 
            photography and styling, and manage all advertising, 
            creating attractive listings across major platforms. Typically, listings go live within 7-10 days of joining.
          </p>
        </div>

        <div className="box">
          <span className="box-number">3</span>
          <h4>Start Earning</h4>
          <p>
            Your property is guest-ready! Sit back, relax, and enjoy the boost in income while we handle all the details.
          </p>
        </div>
      </div>
    </div>

    </div>
  );
};

export default PropertyManagement;

