import React, { useState } from 'react';
import propertyImage from '../assets/propic.png'; // Adjust the path based on your folder structure
import maint from '../assets/maint.png'; // Adjust the path based on your folder structure
import clean from '../assets/clean.png'; 
import sup from '../assets/247sup.png';// Adjust the path based on your folder structure  account
import book from '../assets/book.png';
import account from '../assets/account.png';
import listing from '../assets/listing.png';
import price from '../assets/price.png';
import cross from '../assets/cross.png';
import fitout from '../assets/fitout.png';
import './airbnbservice.css';
import { CiPhone } from "react-icons/ci";
import ContactUs from './ContactUs';

const Airbnbservice = () => {

  const [showPopupOnClick, setShowPopupOnClick] = useState(false);

  const popupStyleButton = {
    position: 'fixed',
    top: '35%',
    left: '50%',
    height:'60%',
    transform: showPopupOnClick ? 'translate(-50%, -50%)' : 'translate(-50%, -150%)',
    backgroundColor: 'black',
    display: showPopupOnClick ? 'block' : 'none',
    zIndex: 9999,
    borderRadius: '15px', // Adds a border radius
   
  };

  const handleClose = () => {
    setShowPopupOnClick(false);
  };


  return (

    <div className='main-pho-section-wrap'>

    <div className="popup" style={popupStyleButton}>

                  <button className="close-button" onClick={handleClose}>
                      X
                    </button>
                    <ContactUs/>
                  </div>
     <h3 className='pro-pho-head'>What we offer</h3>
    <div className="photography-section">
      <div className="text-content">
      
        <h3 className='service-head'>Expert Property Photography</h3>
        <p className='service-head-p'>
        
          Capturing high-quality images is essential for making your property stand out in listings. 
          We are committed to your success,which is why we go the extra mile to deliver 
          striking and vibrant photos that showcase your space at its best.
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={propertyImage}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>Airbnb Fitout and Styling</h3>
        <p className='service-head-p'>
            Transform your property into an inviting and stylish retreat with our expert Airbnb fitout and styling services. 
            We understand that a well-designed space can enhance the guest experience and boost your rental's appeal. Our team works closely 
            with you to create a cohesive and aesthetically pleasing environment that reflects your property's unique character. From selecting the right furnishings and decor to 
            optimizing layout and functionality, we ensure every detail is tailored to attract guests and maximize your rental income.
          
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={fitout}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>Home Maintenance Management</h3>
        <p className='service-head-p'>
          Our goal is to keep your property in top shape. We swiftly 
          handle any maintenance concerns, ensuring your home remains 
          in optimal condition for a pleasant experience for your guests.
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={maint}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>Cleaning & Linen Services</h3>
        <p className='service-head-p'>
          AirBooked is the ideal choice for expert cleaning solutions, 
          with our internal well-trained professionals in the field. 
          Our cleaning pros adhere to rigorous standards to guarantee your 
          home is impeccably cleaned, ensuring guests leave it just as they found it.
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={clean}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>24/7 Guest Support</h3>
        <p className='service-head-p'>
          
          Our dedicated guest communication team is key to our success. When your guests feel appreciated and 
          well looked after, they are much more likely to leave a 5-star review. That’s why we offer 
          continuous support, 24/7, to ensure your guests always have the assistance they need.
                  </p>
       <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={sup}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>Digital House Manuals</h3>
        <p className='service-head-p'>
          
          Our Airbnb Property Management Service offers a customized, professionally crafted Guest Information Manual 
          specifically designed for your home and its surrounding area. 
          This manual is delivered digitally to guests alongside their check-in details, 
          allowing them to familiarize themselves with your property before 
          their stay even starts.,
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={book}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>Dedicated Area Managers</h3>
        <p className='service-head-p'>
          
          Your satisfaction is our top priority. We strive to make your experience with us outstanding in every aspect. 
          To achieve this, we assign an Area Manager as your main point of contact, available to assist you whenever needed. 
          Moreover, managing short-term rentals requires a high level of expertise, and our exceptional 
          team brings years of experience in the property market.
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={account}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>Listing Creation</h3>
        <p className='service-head-p'>
    
          In the short-term rental market, your listing is crucial.We understand the significance of first impressions, 
          which is why we create detailed and engaging 
          listings that highlight the unique features of your property, 
          ensuring it catches potential guests' attention.
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={listing}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>Advanced Pricing Optimization</h3>
        <p className='service-head-p'>
          Aligning pricing with supply and demand is crucial for maintaining high occupancy rates and boosting overall revenue. 
          That’s why we utilize advanced pricing software to monitor fluctuations in supply and demand, 
          allowing us to adjust prices effectively.
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={price}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>

    <div className="photography-section">
      <div className="text-content">
        <h3 className='service-head'>Cross-Platform Advertising</h3>
        <p className='service-head-p'>
          
          We maximize your property's visibility by promoting it on major platforms in Australia, including Airbnb, Booking.com, 
          and various partner websites. Our broad reach allows us to capture over 96% of the market for guests 
          searching for accommodations in Australia. Plus, with our own booking portal 
          and additional platforms, guests can easily find us regardless of their preferred booking site.
        </p>
        <button className="book-chat-ser" onClick={() => setShowPopupOnClick(true)} > <CiPhone size={30} />Book a free appraisal</button>
      </div>
      <div className="image-content">
        <img
          src={cross}
          alt="Property Photography"
          className="photography-image"
        />
      </div>

    </div>
    </div>
  );
};

export default Airbnbservice;
