import React, { useState } from 'react';
import './WhereWeOperate.css'; // Link to your CSS file
import { CiPhone } from "react-icons/ci";
// Sample image imports; replace with actual paths or pass as props
import melbourneImage from '../assets/Melbourne.png';
import morningtonImage from '../assets/Greatocean.png';
import greatOceanRdImage from '../assets/Countryvic.png';
import greatOceanRoadImage from '../assets/Mornington.png';
import ContactUs from './ContactUs';

const WhereWeOperate = () => {

  const [showPopupOnClick, setShowPopupOnClick] = useState(false);

  const popupStyleButton = {
    position: 'fixed',
    top: '35%',
    left: '50%',
    height:'60%',
    transform: showPopupOnClick ? 'translate(-50%, -50%)' : 'translate(-50%, -150%)',
    backgroundColor: 'black',
    display: showPopupOnClick ? 'block' : 'none',
    zIndex: 9999,
    borderRadius: '15px', // Adds a border radius
   
  };

  const handleClose = () => {
    setShowPopupOnClick(false);
  };





  return (
    <div>

    <div className="popup" style={popupStyleButton}>

                  <button className="close-button" onClick={handleClose}>
                      X
                    </button>
                    <ContactUs/>
                  </div>
    <div className="where-we-operate">
      <h3 className="heading-prop">Where We Operate</h3>

      <div className="image-grid">
        <div className="image-container">
          <img className="l" src={melbourneImage} alt="Melbourne" />
          <div className="image-title">Melbourne</div>
        </div>
        
        <div className="image-container">
          <img className="l" src={morningtonImage} alt="Great Ocean Rd" />
          <div className="image-title">Great Ocean rd</div>
        </div>

        <div className="image-container">
          <img className="l" src={greatOceanRdImage} alt="Country Victoria" />
          <div className="image-title">Country Victoria</div>
        </div>

        <div className="image-container">
          <img className="l" src={greatOceanRoadImage} alt="Mornington" />
          <div className="image-title">Mornington</div>
        </div>
      </div>

          
      

    </div>
        <div className='next-container'>

              <h3 className="heading-prop color-next">Ready to Take the Next Step?</h3>
              <p className="next-step-text color-next color-next-p">
                Starting with AirBooked is simple, and scheduling a call is even simpler! 
                Just click the button below, share a few details, and connect with our team 
                today—no obligations attached.
              </p>
             
             
               <button className="call-button" onClick={() => setShowPopupOnClick(true)} ><CiPhone size={30} />Book a free appraisal</button>
              </div>
    </div>
  );
};

export default WhereWeOperate;
