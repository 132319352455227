import './BookingBanner.css';
import React from 'react';
import airbnb from "../assets/AirbnbLogo.png"
import exp from "../assets/Exp.png"
import bookingcom from "../assets/Bookingcom.png"

const BookingBanner = () => {
  return (
    <div className="booking-banner">
      <h4 className='your-list'>Your short-term rental will be listed on:</h4>
      <div className="booking-platforms">
        <img
          src={airbnb}
     
          alt="airbnb logo"
          className="photography-image"
        />
        <img
          src={exp}
          alt="travel logo"
          className="photography-image"
        />
        <img
          src={bookingcom}
          alt="booking dot com logo"
          className="photography-image"
        />
      </div>
    </div>
  );
};

export default BookingBanner;
